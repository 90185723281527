var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionStyled', [_c('HeaderMolecule', {
    attrs: {
      "title": 'Asset Dimension Configurator',
      "buttonText": 'Save Asset Dimension',
      "showBackButton": true,
      "disabledAction": _vm.disableSave,
      "goBackRoute": _vm.goBackRoute,
      "disableText": _vm.disableText
    },
    on: {
      "action": _vm.updateExtensionWithAssetDimension
    }
  }), _c('main', [!_vm.isLoading && _vm.metrics.length === 0 ? _c('NoDataMolecule', {
    attrs: {
      "info": 'The Assets you have selected do not have any supported metrics. Review your Asset Selection',
      "icon": 'asset',
      "buttonText": 'Add/Modify Assets',
      "title": ''
    },
    on: {
      "action": function action($event) {
        return _vm.redirectToAssetSelection($event);
      }
    }
  }) : [_c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Name *',
      "isMuted": false
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('InputAtom', {
    attrs: {
      "value": _vm.name,
      "isReadonly": false,
      "isIdLike": false
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.updateName($event);
      }
    }
  })], 1), _c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Metric *',
      "isMuted": false
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('ExtensionDropdownSelectorMolecule', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableMetrics,
      "selectedOption": _vm.selectedMetric,
      "closeOnSelect": false,
      "shouldNotResetSelectedOption": true,
      "placeholder": 'Select Metric',
      "openDirection": _vm.openDirection,
      "showCustomExtLabel": true,
      "searchable": true
    },
    on: {
      "selectedFilter": _vm.updateSelectedMetric
    }
  })], 1), _c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Aggregation *',
      "isMuted": false
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('ExtensionDropdownSelectorMolecule', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableAggregations,
      "selectedOption": _vm.selectedAggregation,
      "closeOnSelect": true,
      "shouldNotResetSelectedOption": true,
      "openDirection": _vm.openDirection,
      "searchable": true,
      "placeholder": 'Select Aggregation'
    },
    on: {
      "selectedFilter": _vm.updateSelectedAggregation
    }
  })], 1), _c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Metric Unit',
      "isMuted": false
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('ExtensionDropdownSelectorMolecule', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableMetricUnits,
      "selectedOption": _vm.selectedMetricUnit,
      "closeOnSelect": true,
      "shouldNotResetSelectedOption": true,
      "openDirection": _vm.openDirection,
      "searchable": true,
      "placeholder": 'Select Metric Unit'
    },
    on: {
      "selectedFilter": _vm.updateSelectedMetricUnit
    }
  })], 1), _c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Imperial Unit',
      "isMuted": false
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('ExtensionDropdownSelectorMolecule', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableImperialUnits,
      "selectedOption": _vm.selectedImperialUnit,
      "closeOnSelect": true,
      "shouldNotResetSelectedOption": true,
      "openDirection": _vm.openDirection,
      "searchable": true,
      "placeholder": 'Select Imperial Unit'
    },
    on: {
      "selectedFilter": _vm.updateSelectedImperialUnit
    }
  })], 1), _vm.selectedMetric ? _c('div', {
    staticClass: "filters"
  }, [_c('EditableFieldStyled', [_c('LabelAtom', {
    staticClass: "label",
    attrs: {
      "label": 'Filters',
      "isMuted": false
    }
  }), _c('Button', {
    staticClass: "add-filter",
    on: {
      "click": _vm.addFilter
    }
  }, [_c('ArrowWrapper', [_c('PlusIcon', {
    attrs: {
      "size": "1.5x"
    }
  }), _c('TextWrapper', [_vm._v(" Add Filter ")])], 1)], 1)], 1), _c('GroupSelectStyled', [_vm.noAvailableFilters ? _c('div', [_vm._v("No Supported Filters for the Selected Metric " + _vm._s(this.selectedMetric.id))]) : _vm._l(_vm.selectedFilters, function (filter, index) {
    return _c('div', {
      key: index,
      staticClass: "group-select"
    }, [_c('ExtensionDropdownSelectorMolecule', {
      staticClass: "filter-select",
      attrs: {
        "options": _vm.availableFilters,
        "selectedOption": filter.selectedFilter,
        "closeOnSelect": true,
        "shouldNotResetSelectedOption": true,
        "openDirection": _vm.openDirection,
        "searchable": true,
        "placeholder": 'Select Filter Type'
      },
      on: {
        "selectedFilter": function selectedFilter($event) {
          return _vm.updateSelectedFilter($event, index);
        }
      }
    }), _c('ExtensionDropdownSelectorMolecule', {
      staticClass: "opertor-select",
      attrs: {
        "options": filter.availableFilterOperators,
        "selectedOption": filter.selectedFilterOperator,
        "closeOnSelect": true,
        "shouldNotResetSelectedOption": true,
        "openDirection": _vm.openDirection,
        "searchable": true,
        "placeholder": 'Select Operator'
      },
      on: {
        "selectedFilter": function selectedFilter($event) {
          return _vm.updateSelectedFilterOperator($event, index);
        }
      }
    }), _c('ExtensionDropdownSelectorMolecule', {
      staticClass: "filter-select",
      attrs: {
        "options": filter.availableFilterValues,
        "selectedOption": filter.selectedFilterValue,
        "closeOnSelect": true,
        "shouldNotResetSelectedOption": true,
        "openDirection": _vm.openDirection,
        "searchable": true,
        "placeholder": 'Select value'
      },
      on: {
        "selectedFilter": function selectedFilter($event) {
          return _vm.updateSelectedFilterValue($event, index);
        }
      }
    }), _c('Button', {
      on: {
        "click": function click($event) {
          return _vm.deleteFilter(index);
        }
      }
    }, [_c('ArrowWrapper', [_c('TrashIcon', {
      attrs: {
        "size": "1.5x"
      }
    })], 1)], 1)], 1);
  })], 2)], 1) : _vm._e()]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }