<template>
  <MainWrapper>
    <header>
      <Button @click="confirmGoBack()" v-if="showBackButton">
        <IconWrapper>
          <ChevronLeftIcon size="1.5x" />
        </IconWrapper>
      </Button>
      {{ title }}
      <CircleBadge :label="count" size="M" v-if="count" />
    </header>
    <main>
      <div class="search" v-if="enableSearch">
        <SearchBar
          :searchQuery="searchQuery"
          :placeHolder="'actions.search'"
          :isLoading="isLoading"
          @filterShareList="$emit('search', $event)"
        />
      </div>
      <div class="action-button" v-if="buttonText">
        <SimplePopover
          :disabled="disablePopover"
          :trigger="'hover'"
          :placement="'top-end'"
          :popoverContent="''"
          :popoverTextList="disableText"
        >
          <template v-slot:trigger>
            <router-link :to="to" v-if="to" :disabled="disabledAction">
              <IconWrapper :class="{ disabled: disabledAction }">
                <PlusIcon size="1.5x" v-if="mode === 'add'" />
                <SaveIcon size="1.5x" v-else />
                <TextWrapper>{{ buttonText }} </TextWrapper>
              </IconWrapper>
            </router-link>
            <Button @click="$emit('action', buttonText)" v-else :disabled="disabledAction">
              <IconWrapper :class="{ disabled: disabledAction }">
                <PlusIcon size="1.5x" v-if="mode === 'add'" />
                <SaveIcon size="1.5x" v-else />
                <TextWrapper>{{ buttonText }} </TextWrapper>
              </IconWrapper>
            </Button>
          </template>
          <template v-slot:icon>
            <HelpCircleIcon size="1.2x" />
          </template>
        </SimplePopover>
      </div>
    </main>
  </MainWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Button from '@/components/Atomic/Atoms/ButtonStyleless.vue'
import { SaveIcon, PlusIcon, ChevronLeftIcon, HelpCircleIcon } from 'vue-feather-icons'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import chroma from 'chroma-js'
import SimplePopover from '@common/components/src/SimplePopover'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'

const MainWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  > header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    gap: 1rem;
  }
  > main {
    display: flex;
    gap: 3rem;
    .search {
      > div {
        height: 2.5rem;
      }
    }
    > .action-button {
      display: flex;
      gap: 2rem;
      align-items: center;
    }
  }
`

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 35px;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.atomic.primary};
  cursor: pointer;
  padding: 0 0.5rem;
  & > svg {
    color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  }
  &.disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
    border-radius: 0.5rem;
  }
`
const TextWrapper = styled('div')`
  color:${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  font-size: 14px;
  font-weight: 600;
  padding 0.2rem;
`
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: false,
      default: 'save',
    },
    to: {
      type: Object,
      required: false,
    },
    backTo: {
      type: String,
      required: false,
    },
    backToText: {
      type: String,
      required: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      required: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
    goBackRoute: {
      type: Object,
      required: false,
    },
    disableText: {
      type: Array,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
    SaveIcon,
    PlusIcon,
    IconWrapper,
    TextWrapper,
    MainWrapper,
    ChevronLeftIcon,
    CircleBadge,
    SimplePopover,
    HelpCircleIcon,
    SearchBar,
  },
  methods: {
    confirmGoBack() {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        confirmText: this.goBackConfirmText ?? 'Are you sure that you want to quit? All your unsaved changes will be lost!',
        onConfirm: this.goBack,
      })
    },
    goBack() {
      if (this.goBackRoute) {
        this.$router.push(this.goBackRoute)
      } else {
        this.$router.back()
      }
      this.$root.$emit('closeOverlay')
    },
  },
  computed: {
    disablePopover() {
      return Boolean(!this.disableText?.length)
    },
  },
}
</script>
