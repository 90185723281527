<template>
  <AssetDimensionStyled>
    <HeaderMolecule
      :title="'Asset Dimension Configurator'"
      :buttonText="'Save Asset Dimension'"
      :showBackButton="true"
      :disabledAction="disableSave"
      :goBackRoute="goBackRoute"
      :disableText="disableText"
      @action="updateExtensionWithAssetDimension"
    />

    <main>
      <NoDataMolecule
        v-if="!isLoading && metrics.length === 0"
        :info="'The Assets you have selected do not have any supported metrics. Review your Asset Selection'"
        :icon="'asset'"
        :buttonText="'Add/Modify Assets'"
        :title="''"
        @action="redirectToAssetSelection($event)"
      />
      <template v-else>
        <EditableFieldStyled>
          <LabelAtom class="label" :label="'Name *'" :isMuted="false" />
          <LoadingDots v-if="isLoading" />
          <InputAtom :value="name" :isReadonly="false" :isIdLike="false" @update:value="updateName($event)" v-else />
        </EditableFieldStyled>
        <EditableFieldStyled>
          <LabelAtom class="label" :label="'Metric *'" :isMuted="false" />
          <LoadingDots v-if="isLoading" />
          <ExtensionDropdownSelectorMolecule
            :options="availableMetrics"
            :selectedOption="selectedMetric"
            :closeOnSelect="false"
            @selectedFilter="updateSelectedMetric"
            :shouldNotResetSelectedOption="true"
            :placeholder="'Select Metric'"
            :openDirection="openDirection"
            :showCustomExtLabel="true"
            :searchable="true"
            class="select"
            v-else
          />
        </EditableFieldStyled>
        <EditableFieldStyled>
          <LabelAtom class="label" :label="'Aggregation *'" :isMuted="false" />
          <LoadingDots v-if="isLoading" />
          <ExtensionDropdownSelectorMolecule
            :options="availableAggregations"
            :selectedOption="selectedAggregation"
            :closeOnSelect="true"
            @selectedFilter="updateSelectedAggregation"
            :shouldNotResetSelectedOption="true"
            :openDirection="openDirection"
            :searchable="true"
            :placeholder="'Select Aggregation'"
            class="select"
            v-else
          />
        </EditableFieldStyled>
        <EditableFieldStyled>
          <LabelAtom class="label" :label="'Metric Unit'" :isMuted="false" />
          <LoadingDots v-if="isLoading" />
          <ExtensionDropdownSelectorMolecule
            :options="availableMetricUnits"
            :selectedOption="selectedMetricUnit"
            :closeOnSelect="true"
            @selectedFilter="updateSelectedMetricUnit"
            :shouldNotResetSelectedOption="true"
            :openDirection="openDirection"
            :searchable="true"
            :placeholder="'Select Metric Unit'"
            class="select"
            v-else
          />
        </EditableFieldStyled>
        <EditableFieldStyled>
          <LabelAtom class="label" :label="'Imperial Unit'" :isMuted="false" />
          <LoadingDots v-if="isLoading" />
          <ExtensionDropdownSelectorMolecule
            :options="availableImperialUnits"
            :selectedOption="selectedImperialUnit"
            :closeOnSelect="true"
            @selectedFilter="updateSelectedImperialUnit"
            :shouldNotResetSelectedOption="true"
            :openDirection="openDirection"
            :searchable="true"
            :placeholder="'Select Imperial Unit'"
            class="select"
            v-else
          />
        </EditableFieldStyled>
        <div class="filters" v-if="selectedMetric">
          <EditableFieldStyled>
            <LabelAtom class="label" :label="'Filters'" :isMuted="false" />
            <Button @click="addFilter" class="add-filter">
              <ArrowWrapper>
                <PlusIcon size="1.5x" />
                <TextWrapper> Add Filter </TextWrapper>
              </ArrowWrapper>
            </Button>
          </EditableFieldStyled>

          <GroupSelectStyled>
            <div v-if="noAvailableFilters">No Supported Filters for the Selected Metric {{ this.selectedMetric.id }}</div>
            <div class="group-select" v-for="(filter, index) in selectedFilters" :key="index" v-else>
              <ExtensionDropdownSelectorMolecule
                :options="availableFilters"
                :selectedOption="filter.selectedFilter"
                :closeOnSelect="true"
                @selectedFilter="updateSelectedFilter($event, index)"
                :shouldNotResetSelectedOption="true"
                :openDirection="openDirection"
                :searchable="true"
                :placeholder="'Select Filter Type'"
                class="filter-select"
              />
              <ExtensionDropdownSelectorMolecule
                :options="filter.availableFilterOperators"
                :selectedOption="filter.selectedFilterOperator"
                :closeOnSelect="true"
                @selectedFilter="updateSelectedFilterOperator($event, index)"
                :shouldNotResetSelectedOption="true"
                :openDirection="openDirection"
                :searchable="true"
                :placeholder="'Select Operator'"
                class="opertor-select"
              />
              <ExtensionDropdownSelectorMolecule
                :options="filter.availableFilterValues"
                :selectedOption="filter.selectedFilterValue"
                :closeOnSelect="true"
                @selectedFilter="updateSelectedFilterValue($event, index)"
                :shouldNotResetSelectedOption="true"
                :openDirection="openDirection"
                :searchable="true"
                :placeholder="'Select value'"
                class="filter-select"
              />
              <Button @click="deleteFilter(index)">
                <ArrowWrapper>
                  <TrashIcon size="1.5x" />
                </ArrowWrapper>
              </Button>
            </div>
          </GroupSelectStyled>
        </div>
      </template>
    </main>
  </AssetDimensionStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { groupBy, flatten, uniqBy } from 'lodash'
import { PlusIcon, TrashIcon } from 'vue-feather-icons'

import permissionsMixin from '@/mixins/permissions'
import localesMixin from '@/mixins/locales'
import { getUIUnitsDescriptionFromSI } from '@/utils/units'
import { FlashMessages } from '@common/singletons'

import { LoadingDots } from '@common/components'
import NoDataMolecule from './NoDataMolecule.vue'
import LabelAtom from '@/components/Atomic/Atoms/LabelAtom'
import InputAtom from '@/components/Atomic/Atoms/InputAtom'
import ExtensionDropdownSelectorMolecule from './ExtensionDropdownSelectorMolecule.vue'
import Button from '@/components/Atomic/Atoms/ButtonStyleless.vue'
import HeaderMolecule from './HeaderMolecule.vue'

import MY_EXTENSION_QUERY from '#/graphql/extensionBuilder/myExtension.gql'
import METRICS_QUERY from '#/graphql/extensionBuilder/metrics.gql'
import FILTERS_PER_ASSETS_QUERY from '#/graphql/extensionBuilder/filtersPerAsset.gql'
import UPDATE_AD_DEFINITION_MUTATION from '#/graphql/extensionBuilder/updateAssetDimensionDefinition.gql'

const AssetDimensionStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  grid-gap: 2rem;
  justify-items: center;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  height: calc(100% - 2rem);
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  > main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem 3rem 3rem 3rem 3rem 1fr;
    width: 50%;
    grid-gap: 2rem;
    > .filters {
      display: flex;
      flex-direction: column;
    }
  }
`

const EditableFieldStyled = styled('div')`
  display: grid;
  grid-template-columns: 8rem 1fr;
  grid-gap: 5rem;
  align-items: center;
  border-radius: 0.5rem;
  > .label {
    margin: 0.25rem;
    font-size: 14px;
  }
  > input {
    width: 100%;
  }

  border-radius: 8px;
  .select {
    border-radius: 8px;
    background: transparent;
    width: 100%;
    border: 1px solid ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
  }
  > .add-filter {
    width: 6.4rem;
    justify-content: flex-start;
  }
`

const GroupSelectStyled = styled('div')`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-top: 1rem;
  > .group-select {
    display: flex;
    grid-gap: 0.5rem;
    align-items: center;
    border-radius: 0.5rem;
    border-radius: 8px;
    justify-content: flex-end;
    .filter-select,
    .opertor-select,
    .select {
      border-radius: 8px;
      background: transparent;
      width: 40%;
      border: 1px solid ${({ theme }) => chroma(theme.colors.primary).alpha(0.5).css()};
    }
  }
`
const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.atomic.primary};
  cursor: pointer;
  padding: 0 0.5rem;
  & > svg {
    color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  }
`
const TextWrapper = styled('div')`
  color:${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  font-size: 14px;
  padding 0.2rem;
`
export default {
  inject: ['uiSettings'],
  mixins: [permissionsMixin, localesMixin],
  components: {
    AssetDimensionStyled,
    LabelAtom,
    InputAtom,
    EditableFieldStyled,
    ExtensionDropdownSelectorMolecule,
    ArrowWrapper,
    Button,
    TextWrapper,
    GroupSelectStyled,
    PlusIcon,
    TrashIcon,
    HeaderMolecule,
    NoDataMolecule,
    LoadingDots,
  },
  data() {
    return {
      name: 'New AssetDimension',
      selectedMetric: null,
      selectedAggregation: null,
      selectedMetricUnit: null,
      selectedImperialUnit: null,
      selectedOperatorIndex: 0,
      filterBys: [],
      myExtensions: [],
      metrics: [],
      selectedFilters: [],
      filtersPerAsset: [],
      openDirection: '',
      noAvailableFilters: false,
    }
  },
  created() {
    if (this.assetDimensionDefinition) {
      this.intializeSelections()
      if (this.filtersByAsset) {
        this.intializeFilterSelections()
      }
    }
  },
  computed: {
    isLoading() {
      return this.$apollo?.loading
    },
    extensionId() {
      return this.$route.params.id
    },
    assetIds() {
      return this.myExtension?.assetIds ?? []
    },
    myExtension() {
      return this.myExtensions[0]
    },
    assetDimensionDefinitionId() {
      return this.$route.params.assetDimensionId
    },
    assetDimensionDefinition() {
      return this.myExtension?.assetDimensionDefinitions.find(ad => ad.id === this.assetDimensionDefinitionId) ?? []
    },
    availableMetrics() {
      return (
        this.metrics?.map(metric => {
          return {
            id: metric?.id,
            description: metric?.description ?? 'No Description',
            name: metric?.id,
            assetIds: metric?.assetIds,
            label: metric?.id,
            assets: this.getAssetNames(metric?.assetIds),
            filters: metric.filters,
          }
        }) ?? []
      )
    },
    availableAggregations() {
      const filteredMetric = this.selectedMetric?.id ? this.metrics?.filter(metric => metric?.id === this.selectedMetric?.id) : []
      return filteredMetric.length
        ? filteredMetric[0]?.aggregations?.map(aggregation => {
            return {
              id: aggregation?.name,
              label: aggregation?.name,
              siUnit: aggregation?.siUnit,
            }
          })
        : []
    },
    supportedUIUnits() {
      return this.selectedAggregation?.siUnit && this.selectedAggregation?.siUnit !== '1'
        ? getUIUnitsDescriptionFromSI(this.selectedAggregation?.siUnit)
        : []
    },
    availableMetricUnits() {
      return this.supportedUIUnits
        ? this.supportedUIUnits
            .filter(unit => unit.system === 'metric')
            .map(filteredUnit => {
              return {
                id: filteredUnit?.abbr,
                label: filteredUnit?.abbr,
              }
            })
        : []
    },
    availableImperialUnits() {
      return this.supportedUIUnits
        ? this.supportedUIUnits
            .filter(unit => unit.system === 'imperial')
            .map(filteredUnit => {
              return {
                id: filteredUnit?.abbr,
                label: filteredUnit?.abbr,
              }
            })
        : []
    },
    availableFilters() {
      const filtersByAsset = this.selectedMetric?.assetIds.map(asset => {
        const filter = this.filtersPerAsset.filter(fpa => fpa.assetId === asset)
        return filter
      })
      const groupedFilters = groupBy(flatten(filtersByAsset), 'name')
      const mappedFilterData = Object.keys(groupedFilters).map(e => {
        const filterData = groupedFilters[e]
        return {
          assetIds: filterData.map(({ assetId }) => assetId),
          values: uniqBy(flatten(filterData.map(({ values }) => values))),
          type: filterData[0].type,
          name: filterData[0].name,
        }
      })
      const filtersOfSelectedMetric = mappedFilterData.filter(data => this.selectedMetric.filters.find(filter => filter.name === data.name))
      return filtersOfSelectedMetric.length
        ? mappedFilterData?.map(filter => {
            return {
              id: filter?.name,
              label: filter?.name,
              ...filter,
            }
          })
        : []
    },
    invalidFilters() {
      return (
        this.selectedFilters.length > 0 &&
        this.selectedFilters.filter(e => e.selectedFilter === null || e.selectedFilterOperator === null || e.selectedFilterValue === null)
      )
    },
    disableSave() {
      return Boolean(!this.selectedMetric?.id || !this.name || !this.selectedAggregation?.id || this.invalidFilters.length > 0)
    },
    goBackRoute() {
      return {
        name: 'CreateExtension',
        id: this.$route.params.id,
      }
    },
    disableText() {
      const errorTexts = []
      if (!this.$apollo.loading) {
        if (!this.name) {
          errorTexts.push({ id: 'no_ad_name', text: 'Please provide a Valid Asset Dimension Name' })
        }
        if (!this.selectedMetric?.id) {
          errorTexts.push({ id: 'no_ad_metrics', text: 'Please Select a Metric' })
        }
        if (!this.selectedAggregation?.id) {
          errorTexts.push({ id: 'no_ad_aggregation', text: 'Please Select a Aggregation' })
        }
        if (this.invalidFilters.length > 0) {
          errorTexts.push({ id: 'no_ad_filters', text: 'Please Select Valid Filters' })
        }
      }
      return errorTexts
    },
  },
  methods: {
    redirectToAssetSelection() {
      this.$router.push({
        name: 'AddAssets',
      })
    },
    intializeSelections() {
      this.name = this.assetDimensionDefinition?.name
      this.selectedMetric = this.availableMetrics.find(metric => metric.id === this.assetDimensionDefinition?.metricId)
      this.selectedAggregation = this.availableAggregations.find(
        aggregation => aggregation.id === this.assetDimensionDefinition?.aggregation,
      )
      this.selectedImperialUnit = this.availableImperialUnits.find(iUnit => iUnit.id === this.assetDimensionDefinition?.imperialUnit)
      this.selectedMetricUnit = this.availableMetricUnits.find(mUnit => mUnit.id === this.assetDimensionDefinition?.metricUnit)
    },
    intializeFilterSelections() {
      this.assetDimensionDefinition.filters?.map((filterData, i) => {
        const selectedFilter = this.availableFilters.find(filter => filter.id === filterData?.field)
        if (selectedFilter) {
          this.addFilter()
          this.updateSelectedFilter(selectedFilter, i)
          const selectedOperator = this.selectedFilters[i].availableFilterOperators.find(operator => operator.id === filterData?.operator)
          this.updateSelectedFilterOperator(selectedOperator, i)
          const selectedValue = this.selectedFilters[i].availableFilterValues.find(operator => operator.id === filterData?.values[0])
          this.updateSelectedFilterValue(selectedValue, i)
        }
      })
    },
    updateName(value) {
      this.name = value
    },
    addFilter() {
      if (this.availableFilters.length > 0) {
        this.noAvailableFilters = false
        this.selectedFilters.push({
          selectedFilter: null,
          availableFilterOperators: [],
          availableFilterValues: [],
          selectedFilterOperator: null,
          selectedFilterValue: null,
        })
      } else {
        this.noAvailableFilters = true
      }
    },
    deleteFilter(index) {
      this.selectedFilters.splice(index, 1)
    },
    populateFilterOperator(filterData, index) {
      if (filterData) {
        if (filterData.type === 'string') {
          this.selectedFilters[index].availableFilterOperators = [
            { id: 'EQ', label: '=' },
            { id: 'NEQ', label: '≠' },
          ]
        } else {
          this.selectedFilters[index].availableFilterOperators = [{ id: 'EQ', label: '=' }]
        }
      } else {
        this.selectedFilters[index].availableFilterOperators = []
      }
    },
    populateFilterValues(filterData, index) {
      if (filterData) {
        this.selectedFilters[index].availableFilterValues =
          filterData?.values?.map(data => {
            return {
              id: data,
              label: data,
            }
          }) ?? []
      } else {
        this.selectedFilters[index].availableFilterValues = []
      }
    },
    updateSelectedMetric(args) {
      this.selectedMetric = args
      this.selectedAggregation = null
      this.selectedImperialUnit = null
      this.selectedMetricUnit = null
      this.selectedFilters = []
      this.noAvailableFilters = false
    },
    updateSelectedAggregation(args) {
      this.selectedAggregation = args
      this.selectedImperialUnit = null
      this.selectedMetricUnit = null
    },
    updateSelectedMetricUnit(args) {
      this.selectedMetricUnit = args
    },
    updateSelectedImperialUnit(args) {
      this.selectedImperialUnit = args
    },
    updateSelectedFilter(args, index) {
      this.selectedFilters[index].selectedFilter = args
      this.populateFilterOperator(args, index)
      this.populateFilterValues(args, index)
    },
    updateSelectedFilterOperator(args, index) {
      this.selectedFilters[index].selectedFilterOperator = args
    },
    updateSelectedFilterValue(args, index) {
      this.selectedFilters[index].selectedFilterValue = args
    },
    getAssetNames(assetIds) {
      const foundedAssets = this.myExtension?.assets?.filter(asset => assetIds.includes(asset?.id)) ?? []
      return foundedAssets?.map(({ name }) => name) ?? []
    },
    async updateExtensionWithAssetDimension() {
      const filters = this.selectedFilters.length
        ? this.selectedFilters?.map(filter => {
            return {
              field: filter?.selectedFilter?.id,
              operator: filter?.selectedFilterOperator?.id,
              values: { set: filter?.selectedFilterValue?.id },
            }
          })
        : []
      try {
        const adDefinition = await this.$apollo.mutate({
          mutation: UPDATE_AD_DEFINITION_MUTATION,
          variables: {
            data: {
              name: this.name,
              metricId: this.selectedMetric?.id,
              aggregation: this.selectedAggregation?.id,
              siUnit: this.selectedAggregation?.siUnit,
              metricUnit: this.selectedMetricUnit?.id,
              imperialUnit: this.selectedImperialUnit?.id,
              filters: {
                createMany: {
                  data: filters,
                  skipDuplicates: false,
                },
              },
            },
            extensionId: this.extensionId,
            assetDimensionDefinitionId: this.$route?.params?.assetDimensionId,
          },
        })
        const definition = adDefinition?.data?.updateAssetDimensionDefinition
        if (definition?.id) {
          FlashMessages.$emit('success', `Asset Dimension - ${definition?.name} Saved`, {
            timeout: 3000,
          })
          this.$router.push({
            name: 'CreateExtension',
            params: {
              id: this.extensionId,
            },
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    myExtensions: {
      query: MY_EXTENSION_QUERY,
      variables() {
        return {
          where: {
            id: {
              equals: this.extensionId,
            },
          },
        }
      },
      skip() {
        return !this.extensionId
      },
      fetchPolicy: 'network-only',
    },
    metrics: {
      query: METRICS_QUERY,
      variables() {
        return {
          assetIds: this.assetIds,
        }
      },
      skip() {
        return !this.assetIds.length
      },
      result({ data }) {
        if (data?.metrics) {
          this.intializeSelections()
          this.intializeFilterSelections()
        }
      },
    },
    filtersPerAsset: {
      query: FILTERS_PER_ASSETS_QUERY,
      variables() {
        return {
          assetIds: this.assetIds,
        }
      },
      skip() {
        return !this.assetIds.length
      },
    },
  },
}
</script>
