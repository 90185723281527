var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InputAtom', {
    attrs: {
      "type": "text",
      "value": _vm.value,
      "readonly": _vm.isReadonly
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:value', $event.target.value);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }