var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainWrapper', [_c('header', [_vm.showBackButton ? _c('Button', {
    on: {
      "click": function click($event) {
        return _vm.confirmGoBack();
      }
    }
  }, [_c('IconWrapper', [_c('ChevronLeftIcon', {
    attrs: {
      "size": "1.5x"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.title) + " "), _vm.count ? _c('CircleBadge', {
    attrs: {
      "label": _vm.count,
      "size": "M"
    }
  }) : _vm._e()], 1), _c('main', [_vm.enableSearch ? _c('div', {
    staticClass: "search"
  }, [_c('SearchBar', {
    attrs: {
      "searchQuery": _vm.searchQuery,
      "placeHolder": 'actions.search',
      "isLoading": _vm.isLoading
    },
    on: {
      "filterShareList": function filterShareList($event) {
        return _vm.$emit('search', $event);
      }
    }
  })], 1) : _vm._e(), _vm.buttonText ? _c('div', {
    staticClass: "action-button"
  }, [_c('SimplePopover', {
    attrs: {
      "disabled": _vm.disablePopover,
      "trigger": 'hover',
      "placement": 'top-end',
      "popoverContent": '',
      "popoverTextList": _vm.disableText
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_vm.to ? _c('router-link', {
          attrs: {
            "to": _vm.to,
            "disabled": _vm.disabledAction
          }
        }, [_c('IconWrapper', {
          class: {
            disabled: _vm.disabledAction
          }
        }, [_vm.mode === 'add' ? _c('PlusIcon', {
          attrs: {
            "size": "1.5x"
          }
        }) : _c('SaveIcon', {
          attrs: {
            "size": "1.5x"
          }
        }), _c('TextWrapper', [_vm._v(_vm._s(_vm.buttonText) + " ")])], 1)], 1) : _c('Button', {
          attrs: {
            "disabled": _vm.disabledAction
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('action', _vm.buttonText);
            }
          }
        }, [_c('IconWrapper', {
          class: {
            disabled: _vm.disabledAction
          }
        }, [_vm.mode === 'add' ? _c('PlusIcon', {
          attrs: {
            "size": "1.5x"
          }
        }) : _c('SaveIcon', {
          attrs: {
            "size": "1.5x"
          }
        }), _c('TextWrapper', [_vm._v(_vm._s(_vm.buttonText) + " ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "icon",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }], null, false, 3147502273)
  })], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }