<template>
  <WrapperStyled>
    <SimplePopover
      :isShort="true"
      :popoverClass="'avatarPopOver'"
      :popoverContent="''"
      :popoverTitle="'Machines'"
      :placement="'top-start'"
      :trigger="'click'"
      :popoverTextList="assetNames"
    >
      <template v-slot:trigger>
        <CircleBadge class="badge" :label="assets.length" size="S" />
      </template>
    </SimplePopover>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SimplePopover from '@common/components/src/SimplePopover'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'

const WrapperStyled = styled('div')`
  cursor: pointer;
  display: flex;
  .divider {
    width: 1px;
    background: ${({ theme }) => theme.colors.atomic.divider};
    height: 30px;
    margin-left: 0.5rem;
  }
  > .badge {
    background: ${({ theme }) => theme.colors.atomic.divider};
  }
`
export default {
  inject: ['theme'],
  props: {
    assets: {
      type: Array,
      require: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'top-end',
    },
    trigger: {
      type: String,
      required: false,
      default: 'hover',
    },
  },
  components: {
    CircleBadge,
    SimplePopover,
    WrapperStyled,
  },
  computed: {
    assetNames() {
      return this.assets.map(asset => {
        return {
          id: asset,
          text: asset,
        }
      })
    },
  },
}
</script>
