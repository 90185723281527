var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', [_c('SimplePopover', {
    attrs: {
      "isShort": true,
      "popoverClass": 'avatarPopOver',
      "popoverContent": '',
      "popoverTitle": 'Machines',
      "placement": 'top-start',
      "trigger": 'click',
      "popoverTextList": _vm.assetNames
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('CircleBadge', {
          staticClass: "badge",
          attrs: {
            "label": _vm.assets.length,
            "size": "S"
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }