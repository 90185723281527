<template>
  <InputAtom type="text" :value="value" :readonly="isReadonly" @input="$emit('update:value', $event.target.value)" />
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

const InputAtom = styled('input')`
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  color: ${p => p.theme.colors.primary};
  background: ${p => chroma(p.theme.colors.inputBG).alpha(0.1).css()};
  border-bottom: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
  transition: border-color 0.5s ease;
  &:focus {
    border-color: ${p => p.theme.colors.primary};
  }
  &::placeholder {
    color: ${p => p.theme.colors.primary};
  }
`

export default {
  props: {
    value: {
      type: String,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isIdLike: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputAtom,
  },
}
</script>
